<template>
  <div
    @click="cancelAdd"
    class="navbarwrap"
    style="position: relative; height: 100%"
  >
    <div v-if="disabledSidebar" class="navbarOverlay"></div>
    <ul class="navbar" v-if="dictionary && Object.keys(dictionary).length > 0">
      <router-link tag="li" to="/service"
        ><a><icon name="file"></icon><span>Zlecenia</span></a></router-link
      >
      <li class="navbarsubwrap">
        <a href="#" @click.prevent="openSub"
          ><icon name="layers"></icon><span>Kartoteki</span></a
        >
        <ul v-if="isSubOpen" class="navbarsub">
          <router-link tag="li" to="/records/customer"
            ><a><span>Klienci</span></a></router-link
          >
          <router-link tag="li" to="/records/service-technician"
            ><a><span>Serwisanci</span></a></router-link
          >
          <router-link tag="li" to="/records/machinery"
            ><a
              ><span>{{
                dictionary["table_order_devices_type_field_label"]
              }}</span></a
            ></router-link
          >
          <router-link tag="li" to="/records/operators"
            ><a
              ><span>{{
                dictionary["table_order_operators_name_field_label"]
              }}</span></a
            ></router-link
          >
          <router-link tag="li" to="/records/services"
            ><a
              ><span>{{
                dictionary["table_order_is_services_field_label"]
              }}</span></a
            ></router-link
          >
        </ul>
      </li>
      <router-link tag="li" to="/notes"
        ><a><icon name="info"></icon><span>Notatki</span></a></router-link
      >
      <router-link tag="li" to="/raports"
        ><a><icon name="file-text"></icon><span>Raporty</span></a></router-link
      >
      <router-link tag="li" to="/settings/"
        ><a
          ><icon name="settings"></icon><span>Ustawienia</span></a
        ></router-link
      >
    </ul>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import YMmodal from "@/components/Modal.vue";

export default {
  name: "Sidebar",
  data() {
    return {
      isSubOpen: false,
    };
  },
  watch: {
    $route: {
      handler: function (newPage) {
        if (newPage) this.isSubOpen = false;
      },
      deep: true,
      immediate: false,
    },
  },
  computed: {
    ...mapGetters({
      disabledSidebar: "disabledSidebar",
      storage: "storage",
      logo: "logo",
      dictionary: "dictionary",
    }),
  },
  methods: {
    ...mapActions({
      authLogout: "auth/authLogout",
    }),
    logout: function () {
      this.authLogout().then(() => location.reload());
    },
    cancelAdd() {
      if (this.disabledSidebar) {
        this.$buefy.modal.open({
          parent: this,
          component: YMmodal,
          hasModalCard: true,
          props: {
            title: "Czy na pewno chcesz przerwać",
            content: "",
            cancel: "Nie",
            ymHasIcon: true,
            ymIcon: "x",
            submit: `<span class="mal50i">Przerwij</span>`,
          },
        });
      }
    },
    openSub() {
      this.isSubOpen = !this.isSubOpen;
    },
  },
};
</script>

<style lang="scss" scoped>
.navbarOverlay {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 56px;
  z-index: 34;
}
</style>
