<template>
  <div id="app" class="fullscreen-wrapper">
    <b-loading
      :is-full-page="true"
      v-model="isLoading"
      :can-cancel="false"
    ></b-loading>
    <div class="container is-fluid ym-container">
      <div class="columns is-variable is-0 is-multiline is-mobile">
        <div
          :class="
            isAuthenticated
              ? 'column is-2 ym-column-left'
              : 'column is-2 ym-column-left ym-whitebg'
          "
        >
          <Sidebar v-if="isAuthenticated" />
          <div class="ym-logo" v-else>
            <h1>
              <router-link
                to="/"
                :style="{
                  'background-image': `url(${storage?.content}${logo?.content})`,
                }"
                >SERVICEAPP</router-link
              >
            </h1>
          </div>
        </div>
        <div class="column is-12-mobile is-12-tablet is-10-desktop">
          <div class="is-12 is-hidden-desktop">
            <div class="ym-logo ym-logo-space-between">
              <h1>
                <router-link
                  to="/"
                  :style="{
                    'background-image': `url(${storage?.content}${logo?.content})`,
                  }"
                  >SERVICEAPP</router-link
                >
              </h1>
              <section
                class="section section-userbar"
                v-if="
                  isAuthenticated &&
                  dictionary &&
                  Object.keys(dictionary).length > 0
                "
              >
                <button
                  v-if="calendar && calendar !== '' && calendar.is_active"
                  class="button is-small is-secondary"
                  type="button"
                  @click="isModalActive = !isModalActive"
                >
                  <icon name="calendar"></icon>
                </button>
                <div class="userlogout">
                  <a @click="logout" class="not"
                    ><icon name="log-out"></icon
                  ></a>
                </div>
              </section>
            </div>
            <div
              v-if="notes4me && notes4me.length > 0"
              style="padding: 0 16px 16px"
            >
              <SidebarNote
                v-for="note in notes4me"
                :key="note.id"
                :note="note"
              />
            </div>
          </div>
          <div class="ym-column-right">
            <section
              class="section section-userbar is-hidden-touch"
              v-if="
                isAuthenticated &&
                dictionary &&
                Object.keys(dictionary).length > 0
              "
            >
              <div class="d-flex center" style="width: 100%">
                <div class="fullscreen">
                  <div v-if="isFullscreen" @click="toggle">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-maximize"
                    >
                      <path
                        d="M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3"
                      ></path>
                    </svg>
                  </div>
                  <div v-else @click="toggle">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-minimize"
                    >
                      <path
                        d="M8 3v3a2 2 0 0 1-2 2H3m18 0h-3a2 2 0 0 1-2-2V3m0 18v-3a2 2 0 0 1 2-2h3M3 16h3a2 2 0 0 1 2 2v3"
                      ></path>
                    </svg>
                  </div>
                </div>
                <div class="d-flex center" style="margin-left: auto">
                  <button
                    v-if="calendar && calendar !== '' && calendar.is_active"
                    class="button is-small is-secondary"
                    type="button"
                    @click="isModalActive = !isModalActive"
                  >
                    {{ dictionary["calendar"] }}
                  </button>
                  <div class="userlogout">
                    <span v-if="user"
                      >{{ user.firmname }}: {{ user.firstname }}
                      {{ user.lastname }}</span
                    ><a @click="logout"><icon name="log-out"></icon></a>
                  </div>
                </div>
              </div>
              <portal to="BreadCrumbs"></portal>
            </section>
            <router-view />
          </div>
        </div>
      </div>
    </div>
    <b-modal
      v-model="isModalActive"
      v-if="calendar && calendar !== '' && calendar.is_active"
      :width="720"
      scroll="keep"
    >
      <div class="card">
        <div class="card-content">
          <div class="content content-iframe" v-html="calendar.content"></div>
        </div>
      </div>
    </b-modal>
    <NavBar v-if="isAuthenticated" />
    <div class="refresh-container" v-if="hashChanged">
      <div class="notification-body">
        <div class="notification-button" style="text-align: center">
          <p>
            Aktualizacja jest dostępna. Zapisz całą bieżącą pracę i kliknij
            przycisk Aktualizuj poniżej.
          </p>
          <br />
          <p><b>Brak aktualizacji może skutkować błędami.</b></p>
        </div>
        <div class="refresh-button">
          <button class="button is-small is-primary" @click="reloadApp">
            Aktualizuj
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
import NavBar from "@/components/NavBar.vue";
import SidebarNote from "@/components/SidebarNote.vue";

import { mapActions, mapGetters } from "vuex";
import { $axios } from "@/utils/axios";
import store from "./store";
import { refreshPageMixin } from "@/mixins/refresh-page.mixin";
import { api as fullscreen } from "vue-fullscreen";

// import {AUTH_LOGOUT} from '@/store/actions/auth'
// import { $axios } from '@/utils/axios'

export default {
  name: "app",
  components: {
    Sidebar,
    NavBar,
    SidebarNote,
  },
  mixins: [refreshPageMixin],
  data() {
    return {
      color: "#e3001b",
      isModalActive: false,
      isFullscreen: false,
      teleport: true,
      polling: null,
    };
  },
  computed: {
    ...mapGetters({
      isLoading: "isLoading",
      isAuthenticated: "auth/isAuthenticated",
      storage: "storage",
      logo: "logo",
      user: "auth/user",
      dictionary: "dictionary",
      calendar: "calendar",
      notes4me: "auth/notes4me",
    }),
    styleObject() {
      return {
        "--color": "#e3001b",
      };
    },
  },
  mounted() {},
  beforeDestroy() {
    clearInterval(this.polling);
  },
  created: function () {
    this.getAppSettings();
    this.getAppDictionary();
    let isAuthenticated = this.isAuthenticated;
    if (isAuthenticated) {
      this.getUserNotes();
      this.pollData();
    }
    $axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (err) {
        if (
          err.response.status === 401 &&
          err.response.config &&
          !err.response.config.__isRetryRequest
        ) {
          if (isAuthenticated) {
            store.dispatch("auth/authLogout").then(() => {
              location.reload();
            });
          }
        }
        return Promise.reject(err.response);
      }
    );
  },
  methods: {
    ...mapActions({
      getAppSettings: "getAppSettings",
      getAppDictionary: "getAppDictionary",
      authLogout: "auth/authLogout",
      getUserNotes: "auth/userNotes",
    }),
    async toggle() {
      await fullscreen.toggle(this.$el.querySelector(".fullscreen-wrapper"), {
        teleport: this.teleport,
        // callback: (isFullscreen) => {
        //   console.log(isFullscreen)
        //   this.fullscreen = isFullscreen
        // },
      });
      this.isFullscreen = fullscreen.isFullscreen;
    },
    pollData() {
      this.polling = setInterval(() => {
        this.getUserNotes();
      }, 900000);
    },
    logout: function () {
      this.authLogout().then(() => location.reload());
    },
  },
};
</script>

<style>
.refresh-container {
  width: 100%;
  max-width: 310px;
  position: fixed;
  bottom: 15px;
  right: 15px;
  background-color: #fff;
  padding: 25px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.05);
  z-index: 333;
}
.refresh-button {
  margin-top: 16px;
  display: flex;
  justify-content: center;
}
.section-userbar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}
.userlogout {
  align-items: center;
}
.loading-overlay.is-full-page {
  z-index: 1999;
}

.content-iframe {
  position: relative;
  height: 0;
  padding-bottom: 50%;
}

.content-iframe iframe,
.content-iframe object,
.content-iframe embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.d-flex {
  display: flex;
  gap: 8px;
}

.d-flex.center {
  align-items: center;
}

@media (max-width: 851px) {
  .content-iframe {
    min-width: 80vw;
    min-height: calc(100vh - 210px);
  }
}
</style>
