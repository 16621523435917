import { required, email } from "vee-validate/dist/rules";
import { extend } from "vee-validate";

extend("required", {
  ...required,
  message: "wymagane"
});

extend("email", {
  ...email,
  message: "niepoprawny adres"
});
