<template>
<div class="modal-card animation-content">
    <header class="modal-card-head">
      <p class="modal-card-title">{{ title }}</p>
    </header>
    <section class="modal-card-body is-flex">
      <div class="media">
        <div class="media-content" v-html="content"></div>
      </div>
    </section>
    <footer :class="ymHasCancel === true ? 'modal-card-foot' : 'modal-card-foot  flex-end'">
      <button v-if="ymHasCancel" class="button is-medium is-cancel" @click="$parent.close()">{{ cancel }}</button>
      <button v-if="!ymConfirm" class="button is-medium is-dark" @click="defaultAppState">
        <icon v-if="ymHasIcon" :name="ymIcon"></icon>
        <div v-html="submit"></div>
      </button>
      <button v-else :class="ymConfirmClass" @click="confirm">
        <icon v-if="ymHasIcon" :name="ymIcon"></icon>
        <div v-html="submit"></div>
      </button>
    </footer>
</div>
</template>

<script>
import {mapActions} from 'vuex'

export default {
  props: {
    title: String,
    content: String,
    ymHasCancel: {
      type: Boolean,
      default: true
    },
    cancel: String,
    ymHasIcon: {
      type: Boolean,
      default: false      
    },
    ymConfirm: {
      type: Boolean,
      default: false
    },
    ymConfirmClass: {
      type: String,
      default: 'button is-medium is-danger'
    },
    ymOnConfirm: {
      type: Function,
      default: () => { }
    },    
    ymIcon: {
      type: String,
      default: ''
    },
    submit: String
  },
  data() {
    return {
    }
  },
  methods: {
    ...mapActions({
      disabledSidebar: 'setDisabledSidebar'
    }),     
    defaultAppState() {
      this.disabledSidebar(false)
      this.$parent.close()
      this.$store.commit('orders/setOrder', {})
      this.$store.commit('orders/setSelectACustomer', false)
      this.$store.commit('orders/setCustomer', {})
      this.$store.commit('orders/setSelectATechnician', false)
      this.$store.commit('orders/setTechnician', {})
      this.$store.commit('orders/setSelectADevice', false)
      this.$store.commit('orders/setDevice', {})
      this.$router.push({ path: `/` })
    },
    confirm() {
      this.ymOnConfirm()
    }    
  }
}
</script>

<style scoped>
@media print, screen and (min-width: 769px) {
  .modal-card {
    min-width: 320px;
  }
}
.modal-card {
  max-width: 460px;
  width: auto;
}
.modal-card .modal-card-head {
  font-size: 1.25rem;
  font-weight: 600;
}
</style>
